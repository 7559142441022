import {Component} from '@angular/core';
import {AuthService} from "@shared-services/auth.service";
import {Router} from "@angular/router";

@Component({
	selector: 'app-page-not-found',
	templateUrl: './page-not-found.component.html',
	host: {
		role: 'main'
	}
})
export class PageNotFoundComponent {
	isAuth = false;

	constructor(private authService: AuthService, private router: Router) {
		this.isAuth = !!sessionStorage.getItem('id_token');
	}

	ngOninit() {
		localStorage.removeItem('loading');
	}
}
