import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError, map, retry } from 'rxjs/operators';

import { environment } from '../../../environments/environment';

@Injectable({
	providedIn: 'root',
})
export class HttpAimsService {
	apiRoot = environment.aimsApiHost;
	httpOptions: object = {};
	token: string = '';

	constructor( private http: HttpClient ) {}

	getHttp(url: string): Observable<HttpResponse<any>> {
		return this.http
			.get<any>(this.apiRoot + url, this.buildHeaders())
			.pipe(/*retry(2),*/ catchError(this.handleError('getHttp', [])));
	}

	postHttp(url: string, payload: any): Observable<HttpResponse<any>> {
		return this.http
			.post<any>(this.apiRoot + url, payload, this.buildHeaders())
			.pipe(/*retry(2),*/ catchError(this.handleError('postHttp')));
	}

	private buildHeaders() {
		const options: object = {
			headers: new HttpHeaders({
				'Content-Type': 'application/x-www-form-urlencoded'
			}),
			observe: 'response'
		};
		return options;
	}

	private handleError(operation = 'operation', result?: any) {
		return (error: any): Observable<any> => {
			console.error(error); // send the error to remote logging infrastructure // log to console instead
			this.log(`${operation} failed: ${error.message}`); // better job of transforming error for user consumption
			return of(JSON.parse(JSON.stringify(error)) as any); //result as any); // Let the app keep running by returning an empty result.
		};
	}

	private log(message: string) {
		// this.messageService.add(`HttpService: ${message}`);
	}
}
