import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { OAuthEvent, OAuthService } from 'angular-oauth2-oidc';
import { User } from '@shared-models/user.model';
import { FaaAuthorization } from '@shared-models/faa-authorization.model';

@Injectable({
	providedIn: 'root'
})
export class UserService {
	userInfo = new BehaviorSubject<User | null>(null);
	currentAuthorization = new BehaviorSubject<FaaAuthorization | null>(null);
	private currentTgNumber: string = '';
	private userAuthorizations: FaaAuthorization[] = [];
	private enteredDrn: string = '';

	constructor(private oauthService: OAuthService) {
		this.oauthService.events.subscribe(({ type }: OAuthEvent) => {
			if (type === 'logout' || type === 'session_terminated') {
				this.setUserInfo(null);
				this.setCurrentAuthorization(null);
				this.setCurrentTgNumber('');
				localStorage.clear();
			}
		});
	}

	setUserInfo(userInfo: User | null) { this.userInfo.next(userInfo) }
	setCurrentAuthorization(currentAuthorization: FaaAuthorization | null) { this.currentAuthorization.next(currentAuthorization) }

	getCurrentTgNumber() { return this.currentTgNumber }
	setCurrentTgNumber(tgNumber: string) { this.currentTgNumber = tgNumber }

	getUserAuthorizations() { return this.userAuthorizations }
	setUserAuthorizations(userAuthorizations: FaaAuthorization[]) { this.userAuthorizations = userAuthorizations }

	getDrn() { return this.enteredDrn }
	setDrn(drn: string) { this.enteredDrn = drn }
}
