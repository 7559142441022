import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'aNumberDisplay'})

export class ANumberDisplayPipe implements PipeTransform {
	transform(value: string | undefined | null, showMask: Boolean = true): string | undefined {
		if (value) {
			if (!showMask) {
				return value;
			}
			if (value.length < 6){
				let mask = '●●●●●';
				return mask.slice(0, value.length);
			} else {
				return '●●●●●' + value.substring(5);
			}
		} else {
			return '';
		}

	}
}
