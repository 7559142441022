import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ApplicantConvertPipe } from './applicant-convert.pipe';
import { PhoneFormatPipe } from './phone-format.pipe';
import { PhonePipe } from './phone.pipe';
import { SsnDisplayPipe } from './ssn-display.pipe';
// import { SsnFormatPipe } from './ssn-format.pipe';
// import { SsnPipe } from './ssn.pipe';
import { YesNoPipe } from './yes-no.pipe';
import { ItinDisplayPipe } from './itin-display.pipe';
import { ANumberDisplayPipe } from './a-number-display.pipe';

@NgModule({
	imports: [
		CommonModule
	],
	declarations: [
		ApplicantConvertPipe,
		PhoneFormatPipe,
		PhonePipe,
		SsnDisplayPipe,
		// SsnFormatPipe,
		// SsnPipe,
		SsnDisplayPipe,
		ItinDisplayPipe,
		ANumberDisplayPipe,
		YesNoPipe
	],
	exports: [
		ApplicantConvertPipe,
		PhoneFormatPipe,
		PhonePipe,
		SsnDisplayPipe,
		// SsnFormatPipe,
		// SsnPipe,
		SsnDisplayPipe,
		ItinDisplayPipe,
		ANumberDisplayPipe,
		YesNoPipe
	]
})
export class SharedPipesModule { }
