import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class MaskFormatService {
    public static readonly DATE_MASK = "99/99/9999";
    public static readonly MMYYYY_DATE_MASK = "99/9999";
    public static readonly SSN_MASK = "999-99-9999";
    public static readonly PHONE_MASK = "(999) 999-9999";

    private unmask(value: string) {
        return value.split('/').join('').split('_').join('').split('-').join('').split('(').join('').split(')').join('').split(' ').join('');
    }

    mask(value: string, mask: string) {
        if(mask.replace('?', '') === MaskFormatService.DATE_MASK){
            value = this.maskDate(value)
        }
        else if(mask.replace('?', '') === MaskFormatService.MMYYYY_DATE_MASK){
            value = this.maskMMYYYYDate(value)
        }
        else if(mask.replace('?', '') === MaskFormatService.SSN_MASK){
            value = this.maskSsn(value)
        }
        else if(mask.replace('?', '') === MaskFormatService.PHONE_MASK){
            value = this.maskPhone(value)
        }
        return value;
    }

    private maskDate(value: string) {
        value = '' + value;

        let month = value.slice(0, 2)
        let day = value.slice(2, 4)
        let yyyy = value.slice(4, 8)
        let result: string;

        result = month + '/' + day + '/' + yyyy

        return result;
    }

    private maskMMYYYYDate(value: string) {
        value = '' + value;

        let month = value.slice(0, 2)
        let yyyy = value.slice(2, 6);
        let result: string;

        result = month + '/' + yyyy

        return result;
    }

    private maskPhone(value: string) {
        value = '' + value;
        let partOne = value.slice(0, 3);
        let partTwo = value.slice(3, 6);
        let partThree = value.slice(6, 10);
        let result: string;

        result = '(' + partOne + ') ' + partTwo + '-' + partThree;

        return result;
    }

    private maskSsn(value: string) {
        let defaultSSN: string = "___-__-____";
        let maskedSSN: string = '';

        let partOne = "";
        let partTwo = "";
        let partThree = "";

        if(value == null || value == undefined || value.length == 0) {
            return value;
        }

        value = value.replace(/[^0-9]/g, '');
        if(value.length > 9) {
            value = value.slice(0,9);
        }

        if(value.length <=3) {
            maskedSSN = value;
        } else if(value.length <=5) {
            partOne = value.slice(0,3);
            partTwo = value.slice(3,5);
            maskedSSN = partOne + "-" + partTwo;
        } else if(value.length >= 5) {
            partOne = value.slice(0,3);
            partTwo = value.slice(3,5);
            partThree = value.slice(5, value.length);
            maskedSSN = partOne + "-" + partTwo + '-' + partThree;
        }

        return maskedSSN;
    };

    getSelectionPositionForMask(selection: number, mask: string){
        if(mask.replace('?', '') === MaskFormatService.DATE_MASK){
            if(selection > 5){
                selection = selection -2;
            } else if(selection > 2) {
                selection = selection -1
            };
        }
        else if(mask.replace('?', '') === MaskFormatService.MMYYYY_DATE_MASK){
            if(selection > 2){
                selection = selection -1;
            }
        }
        else if(mask.replace('?', '') === MaskFormatService.SSN_MASK){
            if(selection > 6){
                selection = selection -2;
            } else if(selection > 3) {
                selection = selection -1
            };
        }
        else if(mask.replace('?', '') === MaskFormatService.PHONE_MASK){
            if(selection > 6){
                selection = selection -2;
            } else if(selection > 3) {
                selection = selection -1
            };
        }
        return selection;
    };
};
