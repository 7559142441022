import { Component, Input, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/internal/operators/filter';
import { HttpService } from "@shared-services/http.service";
import { UserService } from "@shared-services/user.service";

@Component({
	selector: '[app-nav-global]',
	templateUrl: './nav-global.component.html'
})
export class NavGlobalComponent implements OnInit {
	@Input() isMobileNavCollapsed: Boolean | undefined;
	@Input() isDisplayUserInfo: Boolean | undefined;
	@Input() currentUrl: string | undefined;

	constructor() {
    }

	ngOnInit(): void {

	}

}
