import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ApplicantSearch } from "@shared-models/applicant-search.model";

@Injectable({
	providedIn: 'root'
})
export class SearchService {

	searchSSN = new BehaviorSubject<string | null | undefined>(null);
	searchResults = new BehaviorSubject<ApplicantSearch[]>([]);
	collegePositionsFilled: boolean | undefined;
	fscMatch: boolean | undefined;
	anyTxSubmitted: boolean | undefined;
	

	constructor() { }

	setSearchSSN(searchSSN: string | null | undefined) { this.searchSSN.next(searchSSN) }
	setSearchResults(searchResults: ApplicantSearch[]) { this.searchResults.next(searchResults) }
	setCollegePositionsFilled(collegePositionsFilled: boolean) { this.collegePositionsFilled = collegePositionsFilled }
	setFscMatch(fscMatch: boolean) { this.fscMatch = fscMatch }
	setAnyTxSubmitted(anyTxSubmitted: boolean) { this.anyTxSubmitted = anyTxSubmitted }
}