<div class="navbar navbar-expand-lg navbar-light">
	<div class="container-fluid g-2 mx-5" [ngClass]="{'container-collapsed' : isMobileNavCollapsed, 'container-expanded' : !isMobileNavCollapsed, 'user-info-shown' : isDisplayUserInfo && (currentUrl !== 'home'), 'user-info-hidden' : !isDisplayUserInfo}">
		<div class="navbar-brand">
			<img src="assets/images/logos/fsa-primary.svg"
				class="logo-primary"
				alt="Federal Student Aid an Office of the U.S. Department of Education Logo">
		</div>
		<!--
			todo: remove ngIf from nav to allow menu items to
			display once they introduced in releases following 1.0
		-->
		<nav app-nav-global [isMobileNavCollapsed]="isMobileNavCollapsed" [currentUrl]="currentUrl" [isDisplayUserInfo]="isDisplayUserInfo" *ngIf="currentUrl !== 'home' && currentUrl !== 'page-not-found'"></nav>
	</div>
</div>
