<div class="page-not-found-content">
	<h1 class="error-heading" aria-label="Error: 404 - Page Not Found">
		<span aria-hidden="true" role="presentation">4</span>
		<i aria-hidden="true" role="presentation" class="fa-regular fa-face-frown"></i>
		<span aria-hidden="true" role="presentation">4</span>
	</h1>
	<div class="error-desc">
		<p>Sorry, we can't find the page you're looking for. The page doesn't exist, has been removed, or is currently unavailable.</p>
		<a class="link link-fsa-primary" [routerLink]="isAuth ? '/dashboard' : '/home'">Go to <span [innerText]="isAuth ? 'Dashboard' : 'Home page'"></span></a>
	</div>
</div>
