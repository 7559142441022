import {Component} from '@angular/core';
import {NgbModal, NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {Router} from "@angular/router";

@Component({
	selector: 'app-session-nav',
	templateUrl: './session-nav.component.html'
})
export class SessionNavComponent {
	sessionTitle = "Navigation Error";

	constructor(
		private modalService: NgbModal,
		private activeModal: NgbActiveModal,
		private router: Router) {
	}

	closeNavigationErrorModal() {
		this.activeModal.close();
	}

	cont() {
		history.pushState(null, '/home');
		this.router.navigate(['']).then(() => this.activeModal.close());
	}
}
