import { Injectable } from '@angular/core';
import { NgbModal, NgbActiveModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

@Injectable({
	providedIn: 'root'
})
export class ModalConfigService {
	private modalName: string | undefined;

	constructor() {

	}

	modalOptions(modalName: string): NgbModalOptions | any {
		console.log(modalName);
		let modalOpts: NgbModalOptions;
		if (modalName == 'changeSchool') {
			modalOpts = {
				ariaLabelledBy: 'modalHdgChangeSchool',
				ariaDescribedBy: 'modalDescChangeSchool',
				backdrop: 'static',
				backdropClass: 'modal-backdrop-change-school',
				keyboard: false,
				modalDialogClass: 'modal-change-school',
				scrollable: false,
				size: 'lg',
				windowClass: 'modal-wrapper modal-wrapper-change-school'
			}
			return modalOpts;
		} else if (modalName == 'navigationError') {
			modalOpts = {
				ariaDescribedBy: 'modalDescNavigationError',
				backdrop: 'static',
				backdropClass: 'modal-backdrop-navigation-error',
				keyboard: false,
				modalDialogClass: 'modal-navigation-error',
				scrollable: false,
				size: 'lg',
				windowClass: 'modal-wrapper modal-wrapper-navigation-error'
			}
			return modalOpts;
		} else if (modalName == 'drnRequired') {
			modalOpts = {
				ariaLabelledBy: 'modalHdgDrnRequired',
				ariaDescribedBy: 'modalDescDrnRequired',
				backdrop: 'static',
				backdropClass: 'modal-backdrop-drn-required',
				keyboard: false,
				modalDialogClass: 'modal-drn-required',
				scrollable: false,
				size: 'md',
				windowClass: 'modal-wrapper modal-wrapper-drn-required'
			}
			return modalOpts;
		} else if (modalName == 'sessionLogout') {
			modalOpts = {
				ariaLabelledBy: 'modalHdgSessionLogout',
				ariaDescribedBy: 'modalDescSessionLogout',
				backdrop: 'static',
				backdropClass: 'modal-backdrop-session-logout',
				keyboard: false,
				modalDialogClass: 'modal-session-logout',
				scrollable: false,
				size: 'md',
				windowClass: "modal-wrapper modal-wrapper-session-logout"
			}
			return modalOpts;
		} else if (modalName == 'changeStudent') {
			modalOpts = {
				ariaLabelledBy: 'modalHdgChangeApplicant',
				ariaDescribedBy: 'modalDescChangeApplicant',
				backdrop: 'static',
				backdropClass: 'modal-backdrop-change-student',
				keyboard: false,
				modalDialogClass: 'modal-change-student',
				scrollable: false,
				size: 'lg',
				windowClass: 'modal-wrapper modal-wrapper-change-student'
			}
			return modalOpts;
		} else if (modalName == 'sessionIdle') {
			modalOpts = {
				ariaLabelledBy: 'modalHdgSessionIdle',
				ariaDescribedBy: 'modalDescSessionIdle',
				backdrop: 'static',
				backdropClass: 'modal-backdrop-session-idle',
				keyboard: false,
				modalDialogClass: 'modal-session-idle',
				scrollable: false,
				size: 'md',
				windowClass: 'modal-wrapper modal-wrapper-session-idle',
				centered: true
			}
			return modalOpts;
		} else if (modalName == 'sessionExpired') {
			modalOpts = {
				ariaLabelledBy: 'modalHdgSessionExpired',
				ariaDescribedBy: 'modalDescSessionExpired',
				backdrop: 'static',
				backdropClass: 'modal-backdrop-session-expired',
				keyboard: false,
				modalDialogClass: 'modal-session-expired',
				scrollable: false,
				size: 'md',
				windowClass: 'modal-wrapper modal-wrapper-session-expired',
				centered: true
			}
			return modalOpts;
		}
	}

}
