import { Pipe, PipeTransform } from '@angular/core';
import { MaskFormatService } from '@shared-services/mask.service';

@Pipe({name: 'ssnDisplay'})

export class SsnDisplayPipe implements PipeTransform {
	constructor(private maskService: MaskFormatService) {}

	transform(value: string | undefined | null, showMask: Boolean = true): string | undefined {
		if (value) {
			if (!showMask) {
				return this.maskService.mask(value, '999-99-9999');
			}
			if (value.length < 8){
				let mask = '●●● ●●';
				return mask.slice(0, value.length);
			} else {
				return '●●● ●● ' + value.substring(7);
			}
		} else {
			return '';
		}

	}
}
