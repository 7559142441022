import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { AlertService } from "@shared-services/alert.service";
@Component({
	selector: 'app-alert',
	templateUrl: './alert.component.html'
})
export class AlertComponent implements OnInit {
	@Input() variant!: 'inline' | 'banner';
	@Input() type: 'danger' | 'warning' | 'success' | 'info' | 'primary' | 'secondary' | 'light' | 'dark' = 'info';
	@Input() iconClasses: string = '';
	@Input() alertHeading: string = '';
	@Input() buttonId: string = '';
	@Input() buttonLabel: string = '';
	@Input() buttonCssClasses: string = '';
	@Input() message: string = '';
	@Input() dismissible: boolean = false;
	@Input() show: boolean = false;
	@Input() liveRegion: boolean | undefined;
	@Input() btnDescribedBy: string = '';
	@Input() spacingClasses: string | null = null;
	@Output() alertClosed: EventEmitter<any> = new EventEmitter();

	cssClasses: string = '';
	constructor(private alertService: AlertService) {}

	ngOnInit(): void {
		this.setCssClasses();
	}

	setCssClasses() {
		if (this.variant == 'banner') {
			if (this.type == 'danger') {
				this.cssClasses = 'alert-banner-fsa-error';
				// this.buttonCssClasses = '';
			} else if (this.type == 'warning') {
				this.cssClasses = 'alert-banner-fsa-caution';
				// this.buttonCssClasses = '';
			} else if (this.type == 'info') {
				this.cssClasses = 'alert-banner-fsa-informative';
				this.buttonCssClasses = 'btn-fsa-primary';
			} else if (this.type == 'success') {
				this.cssClasses = 'alert-banner-fsa-success';
				// this.buttonCssClasses = '';
			}

		} else if (this.variant == 'inline') {
			if (this.type == 'danger') {
				this.cssClasses = 'alert-inline-fsa-error';
			} else if (this.type == 'warning') {
				this.cssClasses = 'alert-inline-fsa-caution';
			}
		}
		if (this.spacingClasses) {
			this.cssClasses = this.cssClasses + ' ' + this.spacingClasses;
		}
	}

	alertAction() {
		if (this.buttonId) {
			this.alertService.setTargetElemId(this.buttonId);
		}
	}

	closed(event: any): void {
		this.alertClosed.emit(event);
	}
}
