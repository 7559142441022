import { NgModule } from '@angular/core';
import { DatePipe } from '@angular/common';
import { CommonModule } from '@angular/common';
import { NavSkipComponent } from './nav-skip/nav-skip.component';
import { UsaBannerComponent } from './usa-banner/usa-banner.component';
import { HeaderComponent } from './header/header.component';
import { NavGlobalComponent } from './header/nav-global/nav-global.component';
import { UserInfoComponent } from './header/user-info/user-info.component';
import { FooterComponent } from './footer/footer.component';
import { BackToTopComponent } from './footer/back-to-top/back-to-top.component';
import { RouterModule } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
	providers: [
		DatePipe,
		NgbActiveModal
	],
	imports: [
		CommonModule,
		RouterModule
	],
	declarations: [
		NavSkipComponent,
		BackToTopComponent,
		UsaBannerComponent,
		HeaderComponent,
		NavGlobalComponent,
		UserInfoComponent,
		FooterComponent
	],
	exports: [
		NavSkipComponent,
		BackToTopComponent,
		UsaBannerComponent,
		HeaderComponent,
		NavGlobalComponent,
		UserInfoComponent,
		FooterComponent
	]
})
export class ScaffoldingModule { }
