import {Pipe, PipeTransform} from "@angular/core";

@Pipe({ name: 'yesno' })
export class YesNoPipe implements PipeTransform {

	readonly YES: string = "1 - Yes";
	readonly NO: string = "2 - No";
	readonly NULL_VALUE: string =  "";
	constructor() {}

	transform(originalValue : Boolean | string | null | undefined, outputType: 'yesno' | 'yesblank' | 'yesnoblank'): string {

		if (originalValue == null || originalValue == undefined) {
			return outputType == 'yesno' ? this.NO : this.NULL_VALUE;
		}

		if (typeof originalValue === "string") {
			switch (originalValue) {
				case "true":
				case "True":
				case "Y":
				case "1":
					return 	this.YES;
				case "false":
				case "False":
				case "N":
				case "2":
					return outputType== 'yesblank' ? this.NULL_VALUE : this.NO;
				default:
					return <string>originalValue;
			}
		} else {
			return originalValue ? this.YES
				: outputType == 'yesno' ? this.NO
					: outputType == 'yesblank' ? this.NULL_VALUE
						: originalValue == null || originalValue == undefined ? this.NULL_VALUE : this.NO;
		}
	}
}
