<span id="userInfoFsc" class="user-info-fsc" [innerText]="fsc"></span>|<span id="userInfoTg" class="user-info-tg" [innerText]="tg"></span>|<button #sessionLogoutButton type="button" class="user-info-logout btn btn-link" (click)="userInitiatedLogout(SessionLogout)" aria-describedby="userInfoTg">Log Out</button>
<ng-template #SessionLogout app-logout-confirm let-modal let-c="close" let-d="dismiss" ariaLabelledBy="modalSessionLogoutHeading">
    <div class="modal-header">
        <h1 id="modalHdgSessionLogout" class="modal-title pull-left">Leaving FAFSA Partner Portal</h1>
        <button type="button" class="btn-fsa-close pull-right" aria-label="Close" (click)="d('Close')">
            <span aria-hidden="true">Close</span>
            <i aria-hidden="true" class="fa fa-times"></i>
        </button>
    </div>
    <div class="modal-body">
        <p id="modalDescSessionLogout" class="modal-desc">Select cancel if you'd like to continue using the FAFSA Partner Portal. Otherwise, log out to end your session.</p>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-fsa-secondary" (click)="d('Cancel')">Cancel</button>
        <button type="button" class="btn btn-fsa-primary" (click)="d('Log Out');logout()">Log Out</button>
    </div>    
</ng-template>