import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PageNotFoundComponent } from '@pages/page-not-found/page-not-found.component';
import { AuthGuard } from '@shared-guards/auth.guard';

const routes: Routes = [
	{ path: '',   redirectTo: 'home', pathMatch: 'full' },
	{ path: 'home', loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule), title: 'Home | FAFSA Partner Portal' },
	{ path: 'dashboard', loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule), canActivate: [AuthGuard] },
	{ path: 'isir-request', loadChildren: () => import('./pages/isir-request/isir-request.module').then(m => m.IsirRequestModule) },
	{ path: 'id-verification', loadChildren: () => import('./pages/id-verification/id-verification.module').then(m => m.IdVerificationModule) },
	{ path: 'help', loadChildren: () => import('./pages/help-page/help-page.module').then(m => m.HelpPageModule) },
	{ path: 'app-info', loadChildren: () => import('./pages/app-info/app-info.module').then(m => m.AppInfoModule) },
	{ path: 'page-not-found', component: PageNotFoundComponent, title: 'Page Not Found | FAFSA Partner Portal' },
	{ path: '**', redirectTo: 'page-not-found' },
];

@NgModule({
  imports: [RouterModule.forRoot( routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
