import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import * as uuid from 'uuid';

import { Observable, of } from 'rxjs';
import { catchError, map, retry } from 'rxjs/operators';

import { environment } from './../../../environments/environment';
import { UserService } from './user.service';
import { AuthService } from './auth.service';
import { User } from '@shared-models/user.model';
import { FaaAuthorization } from '@shared-models/faa-authorization.model';

@Injectable({
	providedIn: 'root',
})
export class HttpService {
	apiRoot = environment.restApiHost;
	httpOptions: object = {};
	token: string = '';
	userInfo: User | null = null;
	currentAuthorization: FaaAuthorization | null = null;

	constructor(
		private http: HttpClient,
		private userService: UserService,
		private authService: AuthService
	) {
		this.userService.userInfo.subscribe((userInfo: any) => { this.userInfo = userInfo; });
		this.userService.currentAuthorization.subscribe((currentAuthorization: any) => { this.currentAuthorization = currentAuthorization; });
	}

	getHttp(url: string): Observable<HttpResponse<any>> {
		return this.http
			.get<any>(this.apiRoot + url, this.buildHeaders())
			.pipe(/*retry(2),*/ catchError(this.handleError('getHttp', [])));
	}

	postHttp(url: string, payload: any): Observable<HttpResponse<any>> {
		return this.http
			.post<any>(this.apiRoot + url, payload, this.buildHeaders())
			.pipe(/*retry(2),*/ catchError(this.handleError('postHttp')));
	}

	putHttp(url: string, payload: any): Observable<HttpResponse<any>> {
		return this.http
			.put<any>(this.apiRoot + url, payload, this.buildHeaders())
			.pipe(/*retry(2),*/ catchError(this.handleError('postHttp')));
	}

	deleteHttp(url: string, uid: string): Observable<HttpResponse<any>> {
		return this.http
			.delete<any>(`${this.apiRoot}${url}/${uid}` , this.buildHeaders())
			.pipe(/*retry(2),*/ catchError(this.handleError('postHttp')));
	}

	private buildHeaders() {
		const options: object = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				'X-Correlation-ID': uuid.v4(),
				'X-Request-ID': uuid.v4(),
				'authUserId': uuid.v4(),
				'schoolCode': this.currentAuthorization?.institutionCode ?? '',
				'tgNum': `TG${this.userService.getCurrentTgNumber() ?? ''}`,
				'expertUser': this.currentAuthorization?.destinationCodeType === ('1' || '2') ? "true" : "false",
				'cycles': '2025',
				// 'Role': this.userInfo?.userRole,
				'aims-bypass': "true",
				'Authorization': 'Bearer ' + this.authService.getAuthToken(),
				'drn': this.userService.getDrn(),
				// 'securityBypassFilter': 'true',
			}),
			observe: 'response'
		};
		return options;
	}

	private handleError(operation = 'operation', result?: any) {
		return (error: any): Observable<any> => {
			console.error(error); // send the error to remote logging infrastructure // log to console instead
			this.log(`${operation} failed: ${error.message}`); // better job of transforming error for user consumption
			return of(JSON.parse(JSON.stringify(error)) as any); //result as any); // Let the app keep running by returning an empty result.
		};
	}

	private log(message: string) {
		// this.messageService.add(`HttpService: ${message}`);
	}
}
