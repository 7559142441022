import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router, RoutesRecognized } from '@angular/router';
import { filter, pairwise } from 'rxjs/operators';
import { LocationStrategy } from '@angular/common';

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit {
    isDisplayUserInfo: Boolean = false;

	noStudent: Boolean = true;
    previousUrl: string = '';
    currentUrl: string = '';

    isDashboard: Boolean = false;
    isPage2: Boolean = false;
    isPage3: Boolean = false;
    isPage4: Boolean = false;
    isFsaUser: Boolean = false;
    isMobileNavCollapsed: Boolean = true;

    constructor(
        private router: Router,
        private locationStrategy: LocationStrategy) {
    }

    async ngOnInit() {
        this.router.events.subscribe((e: any) => this.isDisplayUserInfo = !e.routerEvent?.urlAfterRedirects?.includes('page-not-found'));
        this.router.events
            .pipe(filter((e: any) => e instanceof RoutesRecognized),
                pairwise()
            ).subscribe((e: any) => {
                this.previousUrl = e[0].urlAfterRedirects.replace('/', '');
                if (this.previousUrl.includes('?') || this.previousUrl.includes('&')) {
                    this.previousUrl = this.currentUrl.split(/[?]/)[0];
                }
        });

        this.router.events.pipe(
            filter((event) => event instanceof NavigationEnd)
        ).subscribe((event: any) => {
            this.currentUrl = event.url.replace('/', '');

            if (event.url === '/') {
                this.currentUrl = 'home';
            }

            if (this.currentUrl !== 'home') {
                this.isDisplayUserInfo = true;
            } else {
                this.isDisplayUserInfo = false;
            }

            // 'current page' styles are only applied correctly
            // if parameters are removed using the following
            if (this.currentUrl.includes('?') || this.currentUrl.includes('&')) {
                this.currentUrl = this.currentUrl.split(/[?]/)[0];
            }

            this.checkIfLastNavLogout();
        });
    }

    // In production, the only way the current route would be home
    // when the previous/last route was dashboard, isir req or id ver
    // would be when a user has initiated a logout using the
    // global "Log Out" action.
    //
    // In this scenario, we are blocking back navigation using browser button.
    //
    // TODO 1: Create a notification dialog that displays to alert the user
    // to the fact that we are unable to bring them back to a cached page
    // from when they were previously authenticated -- instead of simply
    // blocking the native back action without explanation.
    //
    // TODO 2: In order to prevwnt backcache from displaying user info,
    // incorporate Help into the method, keepng in mind that help
    // will be accessible by both auth and non-auth users. i.e. Prevent
    // back button when previous route was Help(authenticated) but allow
    // it when the previous route is/was Help(public)
    checkIfLastNavLogout() {
        if ((this.currentUrl == 'home') &&
            ((this.previousUrl === 'dashboard') ||
                (this.previousUrl === 'isir-request') ||
                (this.previousUrl === 'id-verification') ||
                (this.previousUrl === 'app-info'))) {
                this.preventBackButton();
        }
    }

    preventBackButton() {
        history.pushState({}, '', location.href);
        this.locationStrategy.onPopState(() => {
            history.pushState({}, '', location.href);
        });
    }

}
