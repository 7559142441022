import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-usa-banner',
	templateUrl: './usa-banner.component.html',
	styleUrls: ['./usa-banner.component.scss']
})
export class UsaBannerComponent implements OnInit {

	usaBannerCollapsed: Boolean = false;

	constructor() {}

	ngOnInit() {}

}
