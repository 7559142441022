<div class="modal-header">
	<h1 id="modalHdgNavigationError" class="modal-title pull-left">{{ sessionTitle }}</h1>
	<button
		type="button"
		class="btn-fsa-close pull-right"
		aria-label="Close"
		(click)="closeNavigationErrorModal()">
		<span aria-hidden="true">Close</span>
		<i aria-hidden="true" class="fa fa-times"></i>
	</button>
</div>
<div class="modal-body">
	<div id="modalDescNavigationError">
		<p class="modal-desc">It looks like you're trying to use your browser's navigation buttons to access features within FAFSA Partner Portal.</p>
		<p class="modal-desc">Use the navigation options provided within the site, such as buttons and hyperlinks, to access the different features.</p>
		<p class="modal-desc">Select Continue to Dashboard to be directed to the Dashboard. You will lose any unsaved changes.</p>
	</div>
</div>
<div class="modal-footer">
	<button type="button" class="btn btn-fsa-secondary" (click)="closeNavigationErrorModal()">Cancel</button>
	<button type="button" class="btn btn-fsa-primary" (click)="cont()">Continue to Dashboard</button>
</div>