export const environment = {
    production: false,
    restApiHost: "https://aed-test-fafsapartnerportalservice.fpsaed.net",
	cpsIntegrationHost: "https://aed-test-cpsintegrationservice.fpsaed.net",
	aimsApiHost: 'https://testsa.ed.gov/aimsoidc',
    goToOnBadReferrer: "https://studentaid.gov",
    showAppInfo: true,
    dateSiteUpdated: "2024-01-29",
    helpCenter: "https://studentaid.gov/help-center/answers/landing",
    CPSSAIGEmail: "mailto:CPSSAIG@ed.gov",
    studentAid: "https://studentaid.gov",
    twitterFafsa: "https://twitter.com/FAFSA",
    facebookStudAid: "https://www.facebook.com/FederalStudentAid",
    instagramStudAid: "https://www.instagram.com/federalstudentaid",
    linkedInStudAid: "https://www.linkedin.com/company/federal-student-aid",
    youtubeStudAid: "https://www.youtube.com/user/FederalStudentAid",
    notice: "https://studentaid.gov/notices",
    usGov: "https://USA.gov",
    edGov: "https://www.ed.gov",
    fscSearch: "https://studentaid.gov/fafsa-app/FSCsearch",
	// authCodeFlowConfig: {
	// 	issuer: 'https://idsvr4.azurewebsites.net',
	// 	redirectUri: window.location.origin, // + '/index.html',
	// 	clientId: 'spa',
	// 	responseType: 'code',
	// 	scope: 'openid', // profile email offline_access api',
	// },
	authCodeFlowConfig: {
		issuer: 'https://testsa.ed.gov/mga/sps/oauth/oauth20/',
		skipIssuerCheck: true,
		redirectUri: window.location.origin, // + '/index.html',
		clientId: 'aee7e33e-defc-4199-rp-aims-fps-test',
		responseType: 'code',
		scope: 'openid', // profile email offline_access api',
		showDebugInformation: true, // ! TODO - Move this to environment.ts and set debug only to lower envs.
	},
	stubPmBackup: true
}
