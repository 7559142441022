import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ScaffoldingModule } from './scaffolding/scaffolding.module';
import { HttpClientModule } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { OAuthModule } from 'angular-oauth2-oidc';
import { PageNotFoundComponent } from '@pages/page-not-found/page-not-found.component';
import { SharedComponentsModule } from '@shared-components/shared-components.module';

@NgModule({
	declarations: [
		AppComponent,
		PageNotFoundComponent
	],
	imports: [
		AppRoutingModule,
		BrowserModule,
		HttpClientModule,
		ScaffoldingModule,
		NgbModule,
		SharedComponentsModule,
		OAuthModule.forRoot()
	],
	providers: [],
	bootstrap: [AppComponent]
})
export class AppModule { }
