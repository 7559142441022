import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

@Component({
	selector: 'app-ssn-display',
	templateUrl: './ssn-display.component.html'
})
export class SsnDisplayComponent implements AfterViewInit {
	@Input() ssn: string = '';
	@Output() showSecureData: EventEmitter<boolean> = new EventEmitter<boolean>();
	@ViewChild('ssnVal', { read: ElementRef }) private ssnVal?: ElementRef<HTMLSpanElement>;

	showMask: Boolean = true;

	constructor() {}

	ngAfterViewInit(): void {
		this.checkMaskState();
	}

	checkMaskState() {
		if (!this.showMask) {
			this.showSecureData.emit(true);
		}
		setTimeout(() => {
			if (this.ssnVal?.nativeElement && this.ssnVal?.nativeElement.innerText.indexOf('●') !== -1) {
				const ssnValMasked = this.ssnVal?.nativeElement.innerHTML;
				const partialMask = '<span class="mask-dots">●●● ●● </span>';
				const lastFour = ssnValMasked?.slice(-4);

				this.ssnVal.nativeElement.innerHTML = partialMask+lastFour;
			}
		}, 0);

	}
}
