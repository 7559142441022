<div class="modal-header">
	<button
		(click)="cont()"
		aria-label="Close"
		class="btn-fsa-close pull-right"
		type="button">
		<span aria-hidden="true">{{btnLabelUp}}</span>
		<i aria-hidden="false" class="fa fa-times"></i>
	</button>
</div>
<div class="modal-body">
	<div class="modal-image" aria-hidden="true">
		<i class="fa-regular fa-clock-four" role="presentation"></i>
	</div>
	<h1 id="modalHdgSessionIdle" class="modal-title pull-left">{{ modalHeader }}</h1>
	<p id="modalDescSessionIdle" class="modal-desc" [attr.aria-live]="timerAnnounce ? 'polite' : 'off'">
		<span class="modal-body-countdown-intro">{{featureDescription}}</span>
		<span class="modal-body-countdown" aria-hidden="true">{{minutesRemaining}} <ng-container>{{minutesRemaining > 1 ? 'mins' : 'min'}}</ng-container> {{secondsRemaining}} <ng-container>{{secondsRemaining == 1 ? 'sec' : 'secs'}}</ng-container></span>
		<span class="sr-only">{{minutesRemaining}} <ng-container>{{minutesRemaining > 1 ? 'minutes' : 'minute'}}</ng-container> {{secondsRemaining}} <ng-container>{{secondsRemaining == 1 ? 'second' : 'seconds'}}</ng-container></span>
	</p>
</div>
<div class="modal-footer">
	<button (click)="close('Cancel')" class="btn btn-fsa-secondary" type="button">{{btnLabelLeft}}</button>
	<button (click)="cont()" class="btn btn-fsa-primary" type="button">{{btnLabelRight}}</button>
</div>
