<div class="modal-header">
	<button
		(click)="closeSessionExpiredModal()"
		aria-label="Close"
		class="btn-fsa-close pull-right"
		type="button">
		<span aria-hidden="true">{{btnLabelUp}}</span>
		<i aria-hidden="false" class="fa fa-times"></i>
	</button>
</div>
<div class="modal-body">
	<div class="modal-image" aria-hidden="true">
		<i class="fa-regular fa-clock-four" role="presentation"></i>
	</div>
	<h1 id="modalHdgSessionExpired" class="modal-title pull-left">{{ modalHeader }}</h1>
	<p id="modalDescSessionExpired" class="modal-desc">
		<span class="modal-body-intro">{{featureDescription}}</span>
	</p>
</div>
<div class="modal-footer">
	<button (click)="closeSessionExpiredModal()" class="btn btn-fsa-primary" type="button">{{btnLabel}}</button>
</div>
