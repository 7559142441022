import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { HttpService } from '@shared-services/http.service';
import { OAuthService } from 'angular-oauth2-oidc';
import { SearchService } from '@shared-services/search.service';
import { Observable } from 'rxjs';
import { UserService } from '@shared-services/user.service';
import { User } from '@shared-models/user.model';

@Injectable({
	providedIn: 'root',
})
export class AuthGuard  {
	searchSSN: string | null | undefined;
	searchResults: any;
	userInfo: User | null = null;

	constructor(
		private router: Router,
		private searchService: SearchService,
		private oauthService: OAuthService,
		private userService: UserService
	) {
		this.userService.userInfo.subscribe((userInfo: User | null) => {
			this.userInfo = userInfo;
		});
	}

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	):
		| Observable<boolean | UrlTree>
		| Promise<boolean | UrlTree>
		| boolean
		| UrlTree {
			return new Promise((resolve) => {
				if (this.oauthService.hasValidIdToken() && this.oauthService.hasValidAccessToken()) {
					if(this.userInfo) {
						resolve(true);
						return true;
					} else {
						this.router.navigate(['home']);
						resolve(false);
						return false;
					}
				} else {
					this.router.navigate(['home']);
					resolve(false);
					return false;
				}
			});
	}
}
