import { Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/internal/operators/filter';
import { HttpService } from "@shared-services/http.service";
import { UserService } from "@shared-services/user.service";
import { AuthService } from '@shared-services/auth.service';
import { FaaAuthorization } from '@shared-models/faa-authorization.model';
import { ModalConfigService } from "@shared-services/modal-config.service";

@Component({
	selector: '[app-user-info]',
	templateUrl: './user-info.component.html',
	providers: [NgbModal]
})
export class UserInfoComponent implements OnInit {
	@ViewChild('sessionLogoutButton', { read: ElementRef }) private sessionLogoutButton?: ElementRef<HTMLButtonElement>;

	fsc: string = '';
	tg: string = '';
	currentUrl: string = '';

	constructor(public httpService: HttpService,
		private router: Router,
		private userService: UserService,
		private activeModal: NgbActiveModal,
		private modalService: NgbModal,
		private modalConfigService: ModalConfigService,
		private authService: AuthService) {
		this.userService.currentAuthorization.subscribe((currentAuthorization: FaaAuthorization | null) => {
			// console.log('USER INFO:\n' + JSON.stringify(userInfo, undefined, 2));
			this.fsc = 'FSC ' + currentAuthorization?.institutionCode;
			this.tg = 'TG ' + this.userService.getCurrentTgNumber();
		});
	}

	ngOnInit() {
        this.router.events.pipe(
            filter((event) => event instanceof NavigationEnd)
        ).subscribe((event: any) => {
            this.currentUrl = event.url.replace('/', '');

            if (event.url === '/') {
                this.currentUrl = 'dashboard';
            }

            // console.log('event.url: ' + event.url);
            // console.log('current url: ' + this.currentUrl);
        });
	}

	userInitiatedLogout(template: TemplateRef<any>) {
		this.modalService.open(template, this.modalConfigService.modalOptions('sessionLogout'));
	}

	logout() {
		this.authService.logout();
		this.router.navigate(['home']);
	}

}
