import { Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { FaaAuthorization } from "@shared-models/faa-authorization.model";
import { environment } from '@environments/environment';
import { AimsService } from './aims.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class AuthService {
	private authToken: string = '';
	faaAuthorizations: FaaAuthorization[] = [];
	idleLoggedOut: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

	constructor(
		private oauthService: OAuthService,
		private aimsService: AimsService
	) {
		this.oauthService.configure(environment.authCodeFlowConfig);
		// this.oauthService.setupAutomaticSilentRefresh();
		this.oauthService.loadDiscoveryDocumentAndTryLogin();
	}

	login() {
		this.oauthService.initCodeFlow();
	}

	logout() {
		this.aimsService.logoutAimsUser();
		this.oauthService.logOut();
		this.setAuthToken('');
	}

	setAuthToken(token: string) {
		this.authToken = token;
	}

	getAuthToken() {
		return this.authToken = window.sessionStorage.getItem('access_token') ?? '';
	}

	setIdleLoggedOut(value: boolean) {
		this.idleLoggedOut.next(value);
	}
}
