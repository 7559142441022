import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-nav-skip',
	templateUrl: './nav-skip.component.html'
})
export class NavSkipComponent implements OnInit {

	constructor() {}

	ngOnInit() {}

	skipNavMain() {
		if (document.getElementById('headingMain')) {
			const skipNavMainTarget = document.getElementById('headingMain');
			skipNavMainTarget?.focus();
		}
	}
}
