import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SsnDisplayComponent } from './ssn-display/ssn-display.component';
import { ItinDisplayComponent } from './itin-display/itin-display.component';
import { ANumberDisplayComponent } from './a-number-display/a-number-display.component';
import { AlertComponent } from './alert/alert.component';
import { SharedPipesModule } from '@shared-pipes/shared-pipes.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SessionNavComponent } from './modals/session-nav/session-nav.component';
import { LoadingComponent } from './loading/loading.component';
import { SessionIdleComponent } from './modals/session-idle/session-idle.component';
import { SessionExpiredComponent } from './modals/session-expired/session-expired.component';

@NgModule({
	imports: [
		CommonModule,
		NgbModule,
		SharedPipesModule
	],
	declarations: [
		SsnDisplayComponent,
		ItinDisplayComponent,
		ANumberDisplayComponent,
		AlertComponent,
		LoadingComponent,
		SessionNavComponent,
		SessionIdleComponent,
		SessionExpiredComponent
	],
	exports: [
		SsnDisplayComponent,
		ItinDisplayComponent,
		ANumberDisplayComponent,
		AlertComponent,
		LoadingComponent,
		SessionNavComponent,
		SessionIdleComponent,
		SessionExpiredComponent
	]
})
export class SharedComponentsModule { }
