import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-session-expired',
  templateUrl: './session-expired.component.html'
})
export class SessionExpiredComponent {
	modalHeader = "Your Session Has Expired";
	featureDescription = "Your session has ended due to inactivity.";
	btnLabelUp = "Close";
	btnLabel: string = "Close";

	constructor(private activeModal: NgbActiveModal) {
	}

	closeSessionExpiredModal() {
		this.activeModal.close('dismiss');
	}

}
