import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})

export class AlertService {

	public targetElemId: string = '';

    constructor() {

    }

	setTargetElemId(data: string) {this.targetElemId = data}
	getTargetElemId() { return this.targetElemId }
	clearTargetElemId() { this.targetElemId = '' }
}