import { DatePipe } from '@angular/common';
import { NavigationEnd, Router } from '@angular/router';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { fromEvent, Observable, Subscription } from 'rxjs';
import { filter } from 'rxjs/internal/operators/filter';
import { environment } from "@environments/environment";
import { RELEASE_UPDATE_DATE } from './footer-release-dates';

@Component({
	selector: 'app-footer',
	templateUrl: './footer.component.html'
})

export class FooterComponent implements OnInit, AfterViewInit {

    isDisplayHelpDeskInfo: Boolean = false;
	showBackToTop: Boolean = false;
    resizeObservable: Observable<Event>;
    resizeSubscription: Subscription;
    lastUpdatedDate: any = new Date();
    releaseUpdateDate = RELEASE_UPDATE_DATE.v_1_0;
	// previousUrl: string = '';
    currentUrl: string = '';
    showUpdateDate?: boolean;

	constructor(private datePipe: DatePipe,
		private router: Router) {
        this.lastUpdatedDate = new Intl.DateTimeFormat('en-US', { weekday: 'long', timeZone: 'UTC' }).format(new Date(environment.dateSiteUpdated))
			+ ', ' + new Intl.DateTimeFormat('en-US',
				{ month: 'long', day: 'numeric', year: 'numeric', timeZone: 'UTC' }).format(new Date(environment.dateSiteUpdated));
		this.resizeObservable = fromEvent(window, 'resize');
		this.resizeSubscription = this.resizeObservable.subscribe( e => {
			setTimeout(() => {
				this.evalDisplayBackToTop();
			}, 900);
		})
    }

    async ngOnInit() {
        this.router.events.pipe(
            filter((event) => event instanceof NavigationEnd)
        ).subscribe((event: any) => {
            this.currentUrl = event.url.replace('/', '');

            if (event.url === '/') {
                this.currentUrl = 'home';
            }

            this.isDisplayHelpDeskInfo = this.currentUrl == 'home';
            this.showUpdateDate = this.currentUrl === 'home';

            // 'current page' styles are only applied correctly
            // if parameters are removed using the following
            if (this.currentUrl.includes('?') || this.currentUrl.includes('&')) {
                this.currentUrl = this.currentUrl.split(/[?]/)[0];
            }

            // console.log('event.url: ' + event.url);
            // console.log('current url: ' + this.currentUrl);

            // this.getActivePage(this.currentUrl);
        });
	}

    ngAfterViewInit(): void {
        this.evalDisplayBackToTop();
    }

	private evalDisplayBackToTop(): void {
		setTimeout(() => {
            const viewportHeight = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
            const viewportHeightTwice = viewportHeight * 2;
            const pageHeight = document.body.offsetHeight;

			if (pageHeight > viewportHeightTwice) {
				this.showBackToTop = true;
			} else {
				this.showBackToTop = false;
			}
		}, 600);
	}

}
