import { Injectable } from '@angular/core';
import { HttpAimsService } from './http-aims.service';

@Injectable({
	providedIn: 'root'
})
export class AimsService {

	constructor(private http: HttpAimsService) {}

	logoutAimsUser() {
		let idToken = window.sessionStorage.getItem('id_token');
		let redirectUrl = window.location.origin;
		this.http.postHttp(`/logout`, `id_token_hint=${idToken}&post_logout_redirect_uri=${redirectUrl}`).subscribe(response => console.log(response));
	}
}
