<footer id="mainFooter" class="container-fluid footer-primary">
	<div class="row">
		<div class="col supplemental"
			[ngClass]="{'is-back-to-top': showBackToTop}">
			<!-- <span *ngIf="showUpdateDate" class="last-updated">Site Last Updated: {{lastUpdatedDate | date: 'fullDate'}}</span> -->
			<span *ngIf="showUpdateDate" class="last-updated">Site Last Updated: {{ lastUpdatedDate }}</span>
			<div app-back-to-top *ngIf="showBackToTop" class="container-fluid back-to-top"></div>
			<span *ngIf="isDisplayHelpDeskInfo" class="help-desk-info">FPS Help Desk (800-330-5947) / <a href="mailto:support@fps.ed.gov">support@fps.ed.gov</a></span>
		</div>
	</div>
	<div class="row">
		<div class="col branding">
			<img src="assets/images/logos/fsa-primary.svg"
				class="logo-primary"
				alt="Federal Student Aid an Office of the U.S. Department of Education Logo" />
		</div>
	</div>
	<div class="row">
		<div class="col links">
			<div class="links-agency">
				<a href="https://studentaid.gov/notices" target="_blank">Notices</a>
				<a href="https://www.usa.gov" target="_blank">usa.gov</a>
				<a href="https://www.ed.gov" target="_blank">ed.gov</a>
			</div>
		</div>
	</div>
</footer>
