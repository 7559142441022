import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'phone'
})
export class PhoneFormatPipe implements PipeTransform {

	transform(rawNum: string) {
		// rawNum = "+1"+ rawNum;

		// const countryCodeStr = rawNum.slice(0, 2);
		const areaCodeStr = rawNum.slice(0, 3);
		const midSectionStr = rawNum.slice(3, 6);
		const lastSectionStr = rawNum.slice(6);

		return /*`${countryCodeStr}*/ `(${areaCodeStr}) ${midSectionStr}-${lastSectionStr}`;
	}

}
