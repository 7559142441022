<div class="usa-banner">
	<div class="usa-accordion">
		<div
			class="usa-banner__header container-fluid"
			[ngClass]="{'usa-banner__header--expanded': !usaBannerCollapsed}">
			<div class="usa-banner__inner row">
				<div class="grid-col-auto">
					<img class="usa-banner__header-flag" src="assets/images/us_flag_small.png" role="presentation">
				</div>
				<div id="usaBtnDesc">
					<div class="grid-col-fill tablet:grid-col-auto">
						<p class="usa-banner__header-text">An official website of the United States government</p>
					</div>
					<button
						type="button"
						class="usa-accordion__button usa-banner__button"
						[attr.aria-expanded]="usaBannerCollapsed ? 'true' : 'false'"
						aria-controls="gov-banner"
						aria-labelledby="usaBtnDesc"
						(click)="usaBannerCollapsed = !usaBannerCollapsed">
						<span class="usa-banner__button-text">Here's how you know</span>
					</button>
				</div>
			</div>
		</div>
		<div class="container usa-banner__content usa-accordion__content" id="gov-banner" [attr.hidden]="!usaBannerCollapsed ? '' : null">
			<div class="grid-row grid-gap-lg">
				<div class="usa-banner__guidance tablet:grid-col-6">
					<img
						class="usa-banner__icon usa-media-block__img"
						src="assets/images/icon-dot-gov.svg"
						role="presentation">
					<div class="usa-media-block__body">
						<p>
							<strong role="heading" aria-level="3">Official websites use .gov</strong><br>
							A <strong>.gov</strong> website belongs to an official government organization in the United States.
						</p>
					</div>
				</div>
				<div class="usa-banner__guidance tablet:grid-col-6">
					<img
						class="usa-banner__icon usa-media-block__img"
						src="assets/images/icon-https.svg"
						role="presentation">
					<div class="usa-media-block__body">
						<p>
							<strong role="heading" aria-level="3">Secure .gov websites use HTTPS</strong>
							<br>A <strong>lock</strong> (<span class="icon-lock" role="img" aria-label="Locked padlock"></span>) or <strong>https://</strong> means you’ve safely connected to the .gov website. Share sensitive information only on official, secure websites.
						</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>