<button id="mobileMenuTrigger" class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navGlobal" aria-controls="navGlobal" aria-expanded="false" (click)="isMobileNavCollapsed = !isMobileNavCollapsed" aria-label="Navigation menu">
    <span *ngIf="isMobileNavCollapsed" aria-hidden="true">MENU</span>
    <svg *ngIf="!isMobileNavCollapsed" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" fill="#6e7578" width="23" height="23">
        <path d="M193.94 256L296.5 153.44l21.15-21.15c3.12-3.12 3.12-8.19 0-11.31l-22.63-22.63c-3.12-3.12-8.19-3.12-11.31 0L160 222.06 36.29 98.34c-3.12-3.12-8.19-3.12-11.31 0L2.34 120.97c-3.12 3.12-3.12 8.19 0 11.31L126.06 256 2.34 379.71c-3.12 3.12-3.12 8.19 0 11.31l22.63 22.63c3.12 3.12 8.19 3.12 11.31 0L160 289.94 262.56 392.5l21.15 21.15c3.12 3.12 8.19 3.12 11.31 0l22.63-22.63c3.12-3.12 3.12-8.19 0-11.31L193.94 256z"></path>
    </svg>
</button>
<div class="collapse navbar-collapse" id="navGlobal">
    <ul class="navbar-nav me-auto mb-2 mb-lg-0">
<!--
        <li class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact:false}">
            <a *ngIf="currentUrl == 'help'" class="nav-link" routerLink="/home">Home</a>
            <span *ngIf="currentUrl == 'home'" class="nav-link nav-current"><span class="visuallyhidden">Current Page: </span>Home</span>
        </li>
-->
        <li *ngIf="isDisplayUserInfo" class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact:false}">
            <a *ngIf="(currentUrl !== 'dashboard') && (currentUrl !== 'home')" class="nav-link" routerLink="/dashboard">Dashboard</a>
            <span *ngIf="currentUrl == 'dashboard'" class="nav-link nav-current"><span class="visuallyhidden">Current Page: </span>Dashboard</span>
        </li>

<!--
        <li class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact:false}">
            <a *ngIf="currentUrl !== 'isir-request' && currentUrl !== 'home'" class="nav-link" routerLink="/isir-request">ISIR Request</a>
            <span *ngIf="currentUrl == 'isir-request'" class="nav-link nav-current"><span class="visuallyhidden">Current Page: </span>ISIR Request</span>
        </li>
        <li class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact:false}">
            <a *ngIf="currentUrl !== 'id-verification' && currentUrl !== 'home'" class="nav-link" routerLink="/id-verification">Verification of Identity</a>
            <span *ngIf="currentUrl == 'id-verification'" class="nav-link nav-current"><span class="visuallyhidden">Current Page: </span>Verification of Identity</span>
        </li>
        <li class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact:false}">
            <a *ngIf="currentUrl !== 'help'" class="nav-link" target="_blank" routerLink="/help">Help</a>
            <span *ngIf="currentUrl == 'help'" class="nav-link nav-current"><span class="visuallyhidden">Current Page: </span>Help</span>
        </li>
-->
    </ul>
    <div *ngIf="isDisplayUserInfo && currentUrl !== 'home'" app-user-info class="user-info"></div>
</div>
