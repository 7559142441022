import {Pipe, PipeTransform} from "@angular/core";

@Pipe({ name: 'phone' })
export class PhonePipe implements PipeTransform {

	constructor() {}

	transform(originalValue : string | null | undefined): string {
		let ssn = '';
		if (originalValue) {
			ssn += originalValue.substring(0, 3);
			ssn += '-' + originalValue.substring(3, 6);
			ssn += '-' + originalValue.substring(6);
		}
		return ssn;
	}
}
