import { Component } from '@angular/core';
import { Subject } from 'rxjs';
import { LoaderService } from '@shared-services/loader.service';

@Component({
	selector: 'app-loading',
	template: `
		<div class="fsa-data-preloader-backdrop"></div>
		<div class="fsa-data-preloader spinner-grow" role="status" aria-label="Loading">
			<span class="sr-only">Loading...</span>
		</div>`
})
export class LoadingComponent {
	isLoading: Subject<boolean> = this.loaderService.isLoading;

	constructor(private loaderService: LoaderService) { }
}
