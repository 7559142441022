<div
	[attr.class]="'alert-wrapper ' + cssClasses"
	[attr.aria-live]="liveRegion ? 'polite' : null"
	[attr.role]="liveRegion ? 'alert' : null">
	<ngb-alert
		#alert
		*ngIf="show"
		[type]="type"
		(closed)="closed(alert)"
		[dismissible]="dismissible"
		role="none">
		<div class="alert-icon">
			<div class="alert-icon-inner">
				<i [ngClass]="iconClasses" *ngIf="iconClasses"></i>
			</div>
		</div>
		<div class="alert-text">
			<div class="alert-text-inner" [ngClass]="{'heading' : alertHeading}">
				<h3 class="alert-text-heading" *ngIf="alertHeading">{{ alertHeading }}</h3>
				<span id="alertMsg" class="alert-text-message">{{ message }}</span>
			</div>
		</div>
		<div *ngIf="buttonLabel" class="alert-button">
			<button [attr.aria-describedby]="btnDescribedBy ? btnDescribedBy : null" [id]="buttonId ? buttonId : null" type="button" [attr.class]="'btn ' + cssClasses" (click)="alertAction()">{{ buttonLabel }}</button>
		</div>
	</ngb-alert>
</div>