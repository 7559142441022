export const applicantConvertTable = {
	transactionSource: [
		{
			original: "Electronic Data Exchange",
			transformed: "EDE"
		},
		{
			original: "Online FAFSA",
			transformed: "FAFSA.gov"
		},
		{
			original: "FAFSA Partner Portal",
			transformed: "FAFSA Partner Portal"
		},
		{
			original: "Paper",
			transformed: "PDF FAFSA"
		},
		{
			original: "FPS",
			transformed: "System Generated"
		},
		{
			original: "FSAIC",
			transformed: "FSAIC"
		},
		],
	transactionType: [
		{
			original: "A",
			transformed: "Application"
		},
		{
			original: "C",
			transformed: "Correction"
		}
		],
		professionalJudgement: [
		{
			original: "Yes",
			transformed: "Y"
		},
		{
			original: "Failed Professional Judgment",
			transformed: "N"
		},
		{
			original: " ",
			transformed: "N"
		}
		],
	fpcCFlag: [
		{
			original: "Y",
			transformed: "Y"
		},
		{
			original: " ",
			transformed: "N"
		}
	],
	dependencyOverride: [
		{
			original: "Dependent to Independent Override",
			transformed: "Y"
		},
		{
			original: "Override Canceled",
			transformed: "N"
		},
		{
			original: "Override Cancelled",
			transformed: "N"
		},
		{
			original: "Failed Dependency Override",
			transformed:  "N"
		},
		{
			original: " ",
			transformed: "N"
		}
	],
	verificationTracking: [
		{
			original: "Standard Verification Group",
			transformed: "V1"
		},
		{
			original: "Custom Verification Group",
			transformed: "V4"
		},
		{
			original: "Aggregate Verification Group",
			transformed: "V5"
		}
	],
}

export interface ApplicantConvertTable {
    original: string;
    transformed: string;
}

import {Pipe, PipeTransform} from "@angular/core";

@Pipe({ name: 'applicantConvert' })
export class ApplicantConvertPipe implements PipeTransform {

    constructor() {}

    transform(originalValue : string, fieldName: string | null | undefined): string {
        const convertTable: ApplicantConvertTable[] = applicantConvertTable[fieldName as keyof typeof applicantConvertTable];
        const matchedRow = convertTable.find((row) => row.original === originalValue);
        if (originalValue && matchedRow) {
            return matchedRow.transformed;
        } else {
            return originalValue;
        }
    }
}
