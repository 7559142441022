import { Component } from '@angular/core';

@Component({
	selector: '[app-back-to-top]',
	templateUrl: './back-to-top.component.html',
	styleUrls: ['./back-to-top.component.scss']
})
export class BackToTopComponent {
	backToTop(): void {
		if (document.getElementById('headingMain')) {
			const headingMain: any = document.getElementById('headingMain');
			headingMain.focus();
		}
	}
}
