import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

@Component({
	selector: 'app-itin-display',
	templateUrl: './itin-display.component.html'
})
export class ItinDisplayComponent implements AfterViewInit {
	@Input() itin: string = '';
	@Output() showSecureData: EventEmitter<boolean> = new EventEmitter<boolean>();
	@ViewChild('itinVal', { read: ElementRef }) private itinVal?: ElementRef<HTMLSpanElement>;

	showMask: Boolean = true;

	constructor() {}

	ngAfterViewInit(): void {
		this.checkMaskState();
	}

	checkMaskState() {
		if (!this.showMask) {
			this.showSecureData.emit(true);
		}
		setTimeout(() => {
			if (this.itinVal?.nativeElement && this.itinVal?.nativeElement.innerText.indexOf('●') !== -1) {
				const itinValMasked = this.itinVal?.nativeElement.innerHTML;
				const partialMask = '<span class="mask-dots">●●● ●● </span>';
				const lastFour = itinValMasked?.slice(-4);

				this.itinVal.nativeElement.innerHTML = partialMask+lastFour;
			}
		}, 0);

	}
}
