import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-session-idle',
  templateUrl: './session-idle.component.html'
})
export class SessionIdleComponent {
	modalHeader = "Are you still there?";
	featureDescription = "Your session will time out in: ";
	btnLabelUp = "Close";
	btnLabelLeft = "End My Session";
	btnLabelRight = "I'm Still Here";
	minutesRemaining!: number;
	secondsRemaining!: number;
	private intervalTimer: any;
	timerAnnounce: boolean = false;

	constructor(private activeModal: NgbActiveModal) {
		this.setRemainingTime();
		this.intervalTimer = setInterval(() => this.updateRemainingTime(), 1000);
	}

	ngAfterViewInit() {
		setTimeout(() => {
			this.timerAnnouncementInterval();
		}, 10000);
	}

	timerAnnouncementInterval() {
		setInterval(() => {
			this.singleTimerAnnouncement();
		}, 10000);
	}

	singleTimerAnnouncement() {
		this.timerAnnounce = true;

		setTimeout(() => {
			this.timerAnnounce = false;
		}, 1000);
	}

	setRemainingTime() {
		const remainingSeconds = 300; // 5 minutes
		this.minutesRemaining = Math.floor(remainingSeconds / 60);
		this.secondsRemaining = remainingSeconds % 60;
	}

	updateRemainingTime() {
		if (this.secondsRemaining > 0) {
			this.secondsRemaining--;
		} else if (this.minutesRemaining > 0) {
			this.minutesRemaining--;
			this.secondsRemaining = 59;
		} else {
			this.close();
			clearInterval(this.intervalTimer);
		}
	}


	close(reason?: string) {
		this.activeModal.dismiss('Cancel');
	}

	cont() {
		this.activeModal.close('Reset');
	}
}
